.StyledWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	justify-content: center;
	border-radius: 10px;
}

.StyledCaption {
	text-align: center;
	margin-top: 10px;
	font: normal normal 300 14px/20px Circe;
}

.StyledCounter {
	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-content: center;
	text-align: center;
	font: normal normal bold 48px/65px Circe;
}
