@import url(https://use.typekit.net/xin7iro.css);
/* @import url(./fonts/circe/index.css); */

body {
  /* overflow-y: scroll; */
  margin: 0;
}



.CountdownDate_StyledWrapper__3ZVIp{width:100%;display:flex;flex-direction:column;justify-content:space-between;justify-content:center;border-radius:10px}.CountdownDate_StyledCaption__3zXEa{text-align:center;margin-top:10px;font:normal normal 300 14px/20px Circe}.CountdownDate_StyledCounter__1rvTY{display:flex;flex-direction:column;justify-content:center;justify-content:center;text-align:center;font:normal normal bold 48px/65px Circe}

